<template>
  <div>
    <Header />
    <Search />
    <Loading v-if="isLoading" />
    <div class="title">
      <div class="pingjia">评价详情</div>
      <!-- <div class="neirong">愉快的写下您对宝贝的使用心得~</div> -->
    </div>
    <div class="content1 w-1920">
      <div class="top w-1200">
        <div class="jieshao">
          <div class="img">
            <Uimage :src="list.skuImg" alt="" />
          </div>
          <div class="shuo">
            {{ list.skuName }}
          </div>
          <div class="price">￥{{ list.skuPrice }}</div>
          <!-- <div class="biao">{{ list.decript }}</div> -->
        </div>
        <div class="ping">
          <div class="wuliu">
            <div class="dafen">物流</div>
            <div class="xing">
              <el-rate v-model="list.logistics" disabled></el-rate>
            </div>
          </div>
          <div class="shangpin">
            <div class="wuliu">
              <div class="dafen">商品</div>
              <div class="xing">
                <el-rate v-model="list.commodity" disabled></el-rate>
              </div>
            </div>
          </div>
          <div class="miaoshu"><span>评论内容：</span>{{ list.content }}</div>
          <div class="pic">
            <div
              class="shangchuan"
              v-for="(res, index) in resources"
              :key="index"
            >
              <Uimage :src="res" alt="" />
            </div>
          </div>
          <div class="huiping" v-if="list.reply">
            <span>商家回复：</span> {{ list.reply }}
          </div>
        </div>
      </div>
    </div>
    <!-- <Baoyou /> -->
    <div class="kongbai"></div>
    <Foot />
  </div>
</template>
<script>
import { post, get } from '@/utils/request';
import Header from '@/components/header.vue';
import Foot from '@/components/foot.vue';
import Search from '@/components/order/search.vue';
import Baoyou from '@/components/order/baoyou.vue';
export default {
  components: {
    Header,
    Foot,
    Search,
    Baoyou,
  },
  data() {
    return {
      value1: '1',
      value2: '3',
      textarea: '',
      isLoading: true,
      list: '',
      resources: '',
    };
  },
  created() {
    this.getorder(this.$route.query.prodCommentId);
  },
  methods: {
    getorder(e) {
      get('api/comment/getordercommentitem?prodCommentId=' + e + '').then(
        (res) => {
          this.isLoading = false;
          this.list = res.data.data;
          this.resources = res.data.data.resources.split(',');
        }
      );
    },
  },
};
</script>
<style lang="scss" scoped>
.title {
  width: 100%;
  height: 96px;
  background: #f5f5f5;
  padding-top: 20px;
  .pingjia {
    font-weight: 600;
    font-size: 24px;
    color: #282828;
    text-align: center;
  }
  .neirong {
    width: 100%;
    font-size: 14px;
    color: #666;
    text-align: center;
    margin-top: 5px;
  }
}
.content1 {
  width: 100%;
  background: #f5f5f5;
  .top {
    width: 1174px;
    margin: 0 auto;
    background: #fff;
    padding-top: 30px;
    padding-left: 60px;
    padding-bottom: 50px;
    display: flex;
    .jieshao {
      width: 252px;
      height: 100%;
      border-right: 2px solid #f2f2f2;
      .img {
        width: 185px;
        height: 160px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .shuo {
        text-align: center;
        width: 185px;
        font-size: 12px;
        color: #7e7e7e;
        margin-top: 20px;
      }
      .price {
        color: #333;
        font-size: 13px;
        width: 185px;
        text-align: center;
        margin-top: 20px;
      }
      .biao {
        color: #a0a0a0;
        font-size: 12px;
        width: 185px;
        text-align: center;
        margin-top: 15px;
        padding: 0;
      }
    }
    .ping {
      padding-left: 58px;
      width: 920px;
      .wuliu {
        font-size: 15px;
        color: #1850ff;
        display: flex;
        line-height: 30px;
        .xing {
          margin-left: 30px;
        }
      }
      .shangpin {
        margin-top: 20px;
      }
      .input {
        margin-top: 5px;
        padding-right: 40px;
      }
      .miaoshu {
        margin-top: 20px;
        span {
          font-weight: 600;
        }
      }
      .pic {
        width: 350px;
        display: flex;
        flex-wrap: wrap;
        .shangchuan {
          margin-top: 10px;
          padding: 0;
          margin-left: 10px;
          img {
            width: 98px;
            height: 94px;
            margin-left: 5px;
          }
        }
      }
      .huiping {
        margin-top: 20px;
        span {
          font-weight: 600;
        }
      }
    }
  }
}
</style>
